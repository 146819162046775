import { Link, navigate } from 'gatsby';
import { message, Form, Input, Button, Select } from 'antd';
import { RiKey2Line } from 'react-icons/ri';
import axios from 'axios';
import moment from 'moment';

import React, { useEffect, useState } from 'react';

import { timezones } from '../../data/timezones';
import { randomString } from '../../config/functions';
import AirtableAccountModal from '../dashboard/airtableAccountModal';
const { Option } = Select;

const AirtableFormSetup = ({ location, uid }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [airtableAccounts, setAirtableAccounts] = useState([]);
    const [connectionId, setConnectionId] = useState('');

    useEffect(async () => {
        const { data, error } = await axios.get(
            `${process.env.GATSBY_BASE_URL}/auth/accounts`,
            {
                params: {
                    uid,
                    form_database_type: 'airtable',
                },
            }
        );
        setAirtableAccounts(data);
        if (data.length > 0) setConnectionId(data[0]._id);
    }, [uid]);

    const createForm = async (values) => {
        setLoading(true);
        if (connectionId !== '' && connectionId !== undefined) {
            values.sheetAccountId = connectionId;
            values.uid = uid;
            values.form_database_type = 'airtable';
            values.formId = randomString(8, 'aA#');

            const { data, error } = await axios.post(
                `${process.env.GATSBY_BASE_URL}/forms/create`,
                values,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            setLoading(false);
            if (!error) navigate('/forms');
        } else {
            message.info(
                'There are a problem with your google sheet authentication.'
            );
        }
    };

    const handleChange = (id) => {
        setConnectionId(id);
    };

    return (
        <div className="form-setup">
            <h2>With Airtable</h2>
            <p>Setup your Form backend with Airtable</p>
            <div className="form-section">
                <div className="oauth-accounts">
                    <>
                        {airtableAccounts.length > 0 ? (
                            <>
                                <label>Choose account</label>
                                <Input.Group compact>
                                    <Select
                                        defaultValue={airtableAccounts[0]._id}
                                        style={{ width: '100%' }}
                                        onChange={handleChange}
                                    >
                                        {airtableAccounts.map((item) => (
                                            <Option
                                                key={item._id}
                                                value={item._id}
                                            >
                                                {item.name} - added{' '}
                                                {moment(
                                                    item.updated_date
                                                ).fromNow()}
                                            </Option>
                                        ))}
                                    </Select>
                                </Input.Group>
                                <AirtableAccountModal type="more" uid={uid} />
                            </>
                        ) : (
                            <AirtableAccountModal type="" uid={uid} />
                        )}
                    </>
                </div>
                <br />
                {airtableAccounts.length > 0 && (
                    <Form
                        form={form}
                        layout="vertical"
                        name="sheetForm"
                        autoComplete="off"
                        initialValues={{}}
                        onFinish={createForm}
                    >
                        <Form.Item
                            label="Give a name to form endpoint"
                            name="formName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input a name!',
                                },
                            ]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            name="timezone"
                            label="Select Timezone"
                            rules={[
                                { required: true, message: 'Missing timezone' },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {timezones.map((tim, key) => (
                                    <Option value={tim.value}>
                                        {tim.timezone}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Airtable Base ID"
                            name="sheetId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input a name!',
                                },
                            ]}
                            extra={
                                <div
                                    style={{
                                        fontWeight: 300,
                                        fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: 'Go here <a href="https://airtable.com/api" target="_blank" rel="noopener noreferrer"> https://airtable.com/api </a> and select your form then you can find the base ID in URL & It begins with <b>app</b>. <br /> ex: https://airtable.com/<b style={{ color: "green" }}>appofMoPxdu7iQ7hf</b>/api/docs',
                                    }}
                                />
                            }
                        >
                            <Input autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                            label="Table Name"
                            name="tabId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input table name!',
                                },
                            ]}
                            extra={
                                <div
                                    style={{
                                        fontWeight: 300,
                                        fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: 'Table Name of the Airtable base.',
                                    }}
                                />
                            }
                        >
                            <Input autoComplete="off" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                loading={loading}
                                htmlType="submit"
                                type="primary"
                            >
                                Create
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default AirtableFormSetup;
