import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import { Breadcrumb, Button, Input, Tooltip } from 'antd';
import { SiGooglesheets, SiAirtable, SiSupabase } from 'react-icons/si';
import { CopyOutlined, SettingOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { ocean } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import MetadataConfig from './metadataConfig';
import { Snippet, Tabs } from '@geist-ui/core';
import Copy from '@geist-ui/icons/copy';
import { copyToClipboard } from '../../config/functions';
import EmailSettings from './emailSettings';

const { TabPane } = Tabs;

function getEndpoint(formId, type) {
    let ep = '';
    if (type === 'sheet') ep = `${process.env.GATSBY_BASE_URL}/s1/${formId}`;
    if (type === 'airtable') ep = `${process.env.GATSBY_BASE_URL}/a1/${formId}`;
    if (type === 'supabase') ep = `${process.env.GATSBY_BASE_URL}/b1/${formId}`;
    return ep;
}

const FormDetails = ({ formId, uid }) => {
    const [formObj, setFormObj] = useState(null);
    const [updateFormInfo, setUpdateFormInfo] = useState(0);

    useEffect(() => {
        if (uid !== '') {
            axios
                .get(`${process.env.GATSBY_BASE_URL}/forms/${uid}/${formId}`)
                .then(function (response) {
                    setFormObj(response.data);
                    // handle success
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        }
    }, [uid, updateFormInfo]);

    const copyThisEndpoint = (link) => {
        copyToClipboard(link);
    };

    return (
        <>
            {formObj !== null ? (
                <div className="forms-detail">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to="/forms">View all Forms</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{formObj.formName}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h3>Form Endpoint</h3>
                    <p style={{ maxWidth: 700 }}>
                        Place this URL in the action attribute of your form.
                        Also, make sure to add method="POST". Finally, ensure
                        that each input has a name attribute.
                    </p>
                    {formObj.form_database_type === 'sheet' && (
                        <Input.Group compact className="snippet-code">
                            <Input
                                style={{ width: 'calc(100% - 200px)' }}
                                defaultValue={`${process.env.GATSBY_BASE_URL}/s1/${formObj.formId}`}
                            />
                            <Tooltip title="copy endpoint">
                                <Button
                                    size="large"
                                    icon={<Copy />}
                                    onClick={() =>
                                        copyThisEndpoint(
                                            `${process.env.GATSBY_BASE_URL}/s1/${formObj.formId}`
                                        )
                                    }
                                />
                            </Tooltip>
                        </Input.Group>
                    )}
                    {formObj.form_database_type === 'airtable' && (
                        <Input
                            addonAfter={<CopyOutlined />}
                            defaultValue={`${process.env.GATSBY_BASE_URL}/a1/${formObj.formId}`}
                        />
                    )}
                    {formObj.form_database_type === 'supabase' && (
                        <Input
                            addonAfter={<CopyOutlined />}
                            defaultValue={`${process.env.GATSBY_BASE_URL}/s1/${formObj.formId}`}
                        />
                    )}

                    <h3>Examples</h3>
                    <Tabs initialValue="1">
                        <Tabs.Item label="Basic" value="1">
                            <SyntaxHighlighter
                                language="html"
                                className="code-highlight"
                                style={ocean}
                            >
                                {`<form action="${getEndpoint(
                                    formObj.formId,
                                    formObj.form_database_type
                                )}" method="POST">
    <input type="text" name="name" />
    <input type="email" name="email" />
    <button type="submit">Send</button>
</form>`}
                            </SyntaxHighlighter>
                        </Tabs.Item>
                        <Tabs.Item label="File upload" value="2">
                            <SyntaxHighlighter
                                language="html"
                                className="code-highlight"
                                style={ocean}
                            >
                                {`<form action="${getEndpoint(
                                    formObj.formId,
                                    formObj.form_database_type
                                )}" method="POST" enctype="multipart/form-data">
    <input type="text" name="name" />
    <input type="file" name="image" />
    <button type="submit">Send</button>
</form>`}
                            </SyntaxHighlighter>
                        </Tabs.Item>
                        {/* <Tabs.Item label="Fetch" value="3">
                            <SyntaxHighlighter
                                language="javascript"
                                className="code-highlight"
                                style={ocean}
                            >
                                {`var form = document.getElementById("form");
form.addEventListener("submit", formSubmit);

function formSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append(
        'name',
        document.querySelector('input[name="name"]').value
    )
    formData.append(
        'email',
        document.querySelector('input[name="email"]').value
    )

    fetch("${getEndpoint(formObj.formId, formObj.form_database_type)}", {
            method: "POST",
            body: formData,
    })
    .then(response => console.log(response))
    .catch(error => console.log(error))
}`}
                            </SyntaxHighlighter>
                        </Tabs.Item>
                        <Tabs.Item label="jQuery" value="4">
                            <SyntaxHighlighter
                                language="javascript"
                                className="code-highlight"
                                style={ocean}
                            >
                                {`$("#ajaxForm").submit(function(e){
    e.preventDefault();
    var action = $(this).attr("action");
    $.ajax({
    type: "POST",
    url: action,
    crossDomain: true,
    data: new FormData(this),
    dataType: "json",
    processData: false,
    contentType: false,
    headers: {
      "Accept": "application/json"
    }
  }).done(function() {
     $('.success').addClass('is-active');
  }).fail(function() {
     alert('An error occurred! Please try again later.')
  });
});`}
                            </SyntaxHighlighter>
                        </Tabs.Item>
                        <Tabs.Item label="Axios" value="5">
                            <SyntaxHighlighter
                                language="javascript"
                                className="code-highlight"
                                style={ocean}
                            >
                                {`axios.post("${getEndpoint(
                                    formObj.formId,
                                    formObj.form_database_type
                                )}", {
    message: "Hello, World",
})
.then(response => console.log(response))
.catch(error => console.log(error))
`}
                            </SyntaxHighlighter>
                        </Tabs.Item> */}
                        {/* <Tabs.Item label="Fileupload" value="3"></Tabs.Item> */}
                    </Tabs>

                    <h3>Metadata</h3>
                    <p style={{ maxWidth: 700 }}>
                        You can collect other data without html input fields
                        into Form like <b>created date</b>, <b>country</b>,{' '}
                        <b>Referer Url</b>, <b>Agent</b>, & more. You just have
                        to enable the field and input field key value from your
                        sheet.
                    </p>

                    <MetadataConfig
                        formObj={formObj}
                        triggerUpdate={() =>
                            setUpdateFormInfo(updateFormInfo + 1)
                        }
                    />

                    <h3>Form Database</h3>
                    {formObj.form_database_type === 'sheet' && (
                        <div className="database-type">
                            <SiGooglesheets />
                            <span>Google Sheet</span>
                            <a
                                href={`https://docs.google.com/spreadsheets/d/${formObj.sheetId}`}
                                target="_blank"
                            >
                                view your form submissions
                            </a>
                        </div>
                    )}
                    {formObj.form_database_type === 'airtable' && (
                        <div className="database-type">
                            <SiAirtable />
                            <span>Airtable database</span>
                        </div>
                    )}
                    {formObj.form_database_type === 'supabase' && (
                        <div className="database-type">
                            <SiSupabase />
                            <span>Supabase database</span>
                        </div>
                    )}

                    {/* <h3>Email settings</h3>
                    <p style={{ maxWidth: 700 }}>
                        You can get email notification on form submissions.
                    </p>
                    <EmailSettings formObj={formObj} /> */}
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default FormDetails;
