import { Link, navigate } from 'gatsby';
import { message, Form, Input, Button, Select } from 'antd';
import { RiKey2Line } from 'react-icons/ri';
import axios from 'axios';
import moment from 'moment';

import React, { useEffect, useState } from 'react';

import { timezones } from '../../data/timezones';
import { randomString } from '../../config/functions';
import SupabaseAccountModal from '../dashboard/supabaseAccountModal';
const { Option } = Select;

const SupabaseFormSetup = ({ location, uid }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [connectionId, setConnectionId] = useState('');

    useEffect(async () => {
        // const { data, error } = await axios.get(
        //     `${process.env.GATSBY_BASE_URL}/auth/accounts`,
        //     {
        //         params: {
        //             uid,
        //             form_database_type: 'supabase',
        //         },
        //     }
        // );
        // setAccounts(data);
        // if (data.length > 0) setConnectionId(data[0]._id);
    }, [uid]);

    const createForm = async (values) => {
        setLoading(true);
        if (connectionId !== '' && connectionId !== undefined) {
            values.sheetAccountId = connectionId;
            values.uid = uid;
            values.form_database_type = 'supabase';
            values.formId = randomString(8, 'aA#');

            const { data, error } = await axios.post(
                `${process.env.GATSBY_BASE_URL}/forms/create`,
                values,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            setLoading(false);
            if (!error) navigate('/forms');
        } else {
            message.info(
                'There are a problem with your google sheet authentication.'
            );
        }
    };

    const handleChange = (id) => {
        setConnectionId(id);
    };

    return (
        <div className="form-setup">
            <h2>With Supabase</h2>
            <p>Setup your Form backend with Supabase</p>
            <p>Comming soon</p>
            {/* <div className="form-section">
                <div className="oauth-accounts">
                    <>
                        {accounts.length > 0 ? (
                            <>
                                <label>Choose account</label>
                                <Input.Group compact>
                                    <Select
                                        defaultValue={accounts[0]._id}
                                        style={{ width: '100%' }}
                                        onChange={handleChange}
                                    >
                                        {accounts.map((item) => (
                                            <Option
                                                key={item._id}
                                                value={item._id}
                                            >
                                                {item.name} - added{' '}
                                                {moment(
                                                    item.updated_date
                                                ).fromNow()}
                                            </Option>
                                        ))}
                                    </Select>
                                </Input.Group>
                                <SupabaseAccountModal type="more" uid={uid} />
                            </>
                        ) : (
                            <SupabaseAccountModal type="" uid={uid} />
                        )}
                    </>
                </div>
                <br />
                {accounts.length > 0 && (
                    <Form
                        form={form}
                        layout="vertical"
                        name="sheetForm"
                        autoComplete="off"
                        initialValues={{}}
                        onFinish={createForm}
                    >
                        <Form.Item
                            label="Give a name to form endpoint"
                            name="formName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input a name!',
                                },
                            ]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            name="timezone"
                            label="Select Timezone"
                            rules={[
                                { required: true, message: 'Missing timezone' },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {timezones.map((tim, key) => (
                                    <Option value={tim.value}>
                                        {tim.timezone}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Table name"
                            name="sheetId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input a name!',
                                },
                            ]}
                            extra={
                                <div
                                    style={{
                                        fontWeight: 300,
                                        fontSize: 14,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: 'Your supabase table name',
                                    }}
                                />
                            }
                        >
                            <Input autoComplete="off" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                loading={loading}
                                htmlType="submit"
                                type="primary"
                            >
                                Create
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div> */}
        </div>
    );
};

export default SupabaseFormSetup;
