import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { Empty } from 'antd';
import moment from 'moment';
import { SiGooglesheets, SiAirtable, SiSupabase } from 'react-icons/si';

import CreateModal from './createModal';

const FormsList = ({ uid }) => {
    const [formList, setFormList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (uid !== '') {
            axios
                .get(`${process.env.GATSBY_BASE_URL}/forms/${uid}`)
                .then(function (response) {
                    setFormList(response.data);
                    // handle success
                    setLoading(false);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        }
    }, [uid]);
    return (
        <>
            {!loading && (
                <div className="forms-list">
                    {formList.length > 0 ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    Your Forms
                                </div>
                                <CreateModal />
                            </div>
                            <br />
                            <div className="list">
                                {formList.map((form, key) => (
                                    <Link
                                        to={`/forms/${form.formId}`}
                                        className="form-card"
                                        key={key}
                                    >
                                        <div className="card-inner">
                                            <div className="name">
                                                {form.formName}
                                            </div>
                                            <div className="timezone created_date">
                                                <span>{form.timezone}</span>
                                                <span>
                                                    {moment(
                                                        form.created_date
                                                    ).fromNow()}
                                                </span>
                                            </div>
                                            {form.form_database_type ===
                                                'sheet' && (
                                                <div className="database-type">
                                                    <SiGooglesheets />
                                                    <span>
                                                        Google Sheet database
                                                    </span>
                                                </div>
                                            )}
                                            {form.form_database_type ===
                                                'airtable' && (
                                                <div className="database-type">
                                                    <SiAirtable />
                                                    <span>
                                                        Airtable database
                                                    </span>
                                                </div>
                                            )}
                                            {form.form_database_type ===
                                                'supabase' && (
                                                <div className="database-type">
                                                    <SiSupabase />
                                                    <span>
                                                        Supabase database
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </>
                    ) : (
                        <Empty
                            description="There is no form. Click on below button to setup from backend"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        >
                            <CreateModal />
                        </Empty>
                    )}
                </div>
            )}
        </>
    );
};

export default FormsList;
