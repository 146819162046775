import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Switch } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

const MetadataConfig = ({ formObj, triggerUpdate }) => {
    const [visible, setVisible] = useState(false);
    const [createdDate, setCreatedDate] = useState(formObj.enableCreatedDate);
    const [country, setCountry] = useState(formObj.enableCountry);
    const [refererUrl, setRefererUrl] = useState(formObj.enableRefererUrl);
    const [agent, setAgent] = useState(formObj.enableAgent);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    async function metadataUpdate(enableValue, keyName) {
        const { data, error } = await axios.post(
            `${process.env.GATSBY_BASE_URL}/forms/update`,
            {
                formId: formObj.formId,
                enableValue,
                keyName,
            },
            {
                headers: { 'Content-Type': 'application/json' },
            }
        );

        console.log(formObj);

        triggerUpdate();
    }

    const enableCreatedDate = async (checked) => {
        metadataUpdate(checked, 'enableCreatedDate');
    };

    const enableCountry = (checked) => {
        metadataUpdate(checked, 'enableCountry');
    };

    const enableRefererUrl = (checked) => {
        metadataUpdate(checked, 'enableRefererUrl');
    };

    const enableAgent = (checked) => {
        metadataUpdate(checked, 'enableAgent');
    };

    return (
        <>
            <Button icon={<SettingOutlined />} onClick={showDrawer}>
                Configure Metadata
            </Button>
            <Drawer
                title="Config Metadata for this Form"
                placement="left"
                onClose={onClose}
                visible={visible}
                width="500px"
            >
                <Switch
                    onChange={enableCreatedDate}
                    defaultChecked={createdDate}
                />{' '}
                <label style={{ fontWeight: 500 }}>
                    Enable created date column
                </label>
                <p>
                    After enable, add new column name <b>createdDate</b> into
                    your Sheet
                </p>
                <br />
                <Switch
                    onChange={enableCountry}
                    defaultChecked={country}
                />{' '}
                <label style={{ fontWeight: 500 }}>Enable Country column</label>
                <p>
                    After enable, add new column name <b>country</b> into your
                    Sheet
                </p>
                <br />
                <Switch
                    onChange={enableRefererUrl}
                    defaultChecked={refererUrl}
                />{' '}
                <label style={{ fontWeight: 500 }}>
                    Enable Referer URL column
                </label>
                <p>
                    After enable, add new column name <b>refererUrl</b> into
                    your Sheet
                </p>
                <br />
                <Switch onChange={enableAgent} defaultChecked={agent} />{' '}
                <label style={{ fontWeight: 500 }}>Enable Agent column</label>
                <p>
                    After enable, add new column name <b>agent</b> into your
                    Sheet
                </p>
                <div style={{ overflowX: 'auto', width: '100%' }}>
                    <table className="metadata-table">
                        <tbody>
                            <tr>
                                <td className="number"></td>
                                <td className="number">A</td>
                                <td className="number">B</td>
                                <td className="number">C</td>
                                <td className="number">D</td>
                            </tr>
                            <tr>
                                <td className="number">1</td>
                                <td>createdDate</td>
                                <td>country</td>
                                <td>refererUrl</td>
                                <td>agent</td>
                            </tr>
                            <tr>
                                <td className="number">2</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="number">3</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="number">4</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="number">5</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Drawer>
        </>
    );
};

export default MetadataConfig;
