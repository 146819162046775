import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { Link } from 'gatsby';
import { SiSupabase, SiGooglesheets, SiAirtable } from 'react-icons/si';

const CreateModal = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Link to="/forms/google-sheet">
                <Button
                    type="primary"
                    onClick={showModal}
                    icon={<AppstoreAddOutlined />}
                >
                    Create new form endpoint
                </Button>
            </Link>
            <Modal
                title="Choose your database"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width="700px"
            >
                <div className="database-list">
                    <Link to="/forms/google-sheet">
                        <SiGooglesheets />
                        <span>Google Sheet</span>
                    </Link>
                    <Link to="/forms/airtable">
                        <SiAirtable />
                        <span>Airtable</span>
                    </Link>
                    <Link to="/forms/supabase" style={{ opacity: 0.4 }}>
                        <SiSupabase />
                        <span>Supabase</span>
                    </Link>
                </div>
            </Modal>
        </>
    );
};

export default CreateModal;
