import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Radio, Space, Select, Form, Input, message } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { SiGoogle } from 'react-icons/si';
import moment from 'moment';

const { Option } = Select;

const SheetInfo = ({ accountId }) => {
    const [accountList, setAccountList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [driveFiles, setDriveFiles] = useState([]);
    const [sheetId, setSheetId] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [sheetTabs, setSheetTabs] = useState([]);
    const [sheetHeadRows, setSheetHeadRows] = useState([]);
    const [activeTabId, setActiveTabId] = useState('');

    useEffect(() => {
        async function fetchAccountList() {
            setDriveFiles([]);
            setSheetTabs([]);
            var data = JSON.stringify({
                _id: accountId,
            });

            const response = await axios.post(
                `${process.env.GATSBY_BASE_URL}/list/driveFiles`,
                data,
                { headers: { 'Content-Type': 'application/json' } }
            );
            setDriveFiles(response.data);
        }
        if (accountId !== '') fetchAccountList();
    }, [accountId]);

    const onFileChange = async (value) => {
        message.info('Getting tabs...');
        setSheetId(value);
        setSheetTabs([]);
        var data = JSON.stringify({
            _id: accountId,
            sheetId: value,
        });

        const response = await axios.post(
            `${process.env.GATSBY_BASE_URL}/list/sheetTabs`,
            data,
            { headers: { 'Content-Type': 'application/json' } }
        );
        setSheetTabs(response.data);
    };

    const onTabsChange = async (value) => {
        setSelectedTab(value);
        setActiveTabId(value);
    };

    return (
        <div>
            {driveFiles.length > 0 && (
                <Form.Item
                    name="sheetId"
                    label="Select the spreadsheet"
                    rules={[{ required: true, message: 'Missing spreadsheet' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select the source spreadsheet"
                        optionFilterProp="children"
                        onChange={onFileChange}
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 500 }}
                    >
                        {driveFiles.map((file, key) => (
                            <Option value={file.id} key={key}>
                                {file.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {sheetTabs.length > 0 && (
                <Form.Item
                    name="tabId"
                    label="Select Sheet Tab"
                    rules={[{ required: true, message: 'Missing spreadsheet' }]}
                >
                    <Select
                        showSearch
                        placeholder="Choose sheet Tab Id"
                        optionFilterProp="children"
                        onChange={onTabsChange}
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 500 }}
                    >
                        {sheetTabs.map((tab, key) => (
                            <Option value={tab.title} key={key}>
                                {tab.title}
                            </Option>
                        ))}
                    </Select>

                    {/* {selectedTab !== null && (
                        <a
                            href={`https://docs.google.com/spreadsheets/d/${sheetId}#gid=${activeTabId}`}
                            target="_blank"
                        >
                            View spreadsheet
                        </a>
                    )} */}
                </Form.Item>
            )}
        </div>
    );
};

export default SheetInfo;
