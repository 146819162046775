export const timezones = [
    { value: 'UTC', timezone: 'UTC' },
    { value: 'Pacific/Niue', timezone: 'Niue (GMT-11:00)' },
    { value: 'Pacific/Pago_Pago', timezone: 'Pago Pago (GMT-11:00)' },
    { value: 'Pacific/Honolulu', timezone: 'Hawaii Time (GMT-10:00)' },
    { value: 'Pacific/Rarotonga', timezone: 'Rarotonga (GMT-10:00) ' },
    { value: 'Pacific/Tahiti', timezone: 'Tahiti (GMT-10:00)' },
    { value: 'Pacific/Marquesas', timezone: 'Marquesas (GMT-09:30)' },
    { value: 'America/Anchorage', timezone: 'Alaska Time (GMT-09:00)' },
    { value: 'Pacific/Gambier', timezone: 'Gambier (GMT-09:00)' },
    { value: 'America/Los_Angeles', timezone: 'Pacific Time (GMT-08:00)' },
    {
        value: 'America/Tijuana',
        timezone: 'Pacific Time - Tijuana (GMT-08:00)',
    },
    {
        value: 'America/Vancouver',
        timezone: 'Pacific Time - Vancouver (GMT-08:00)',
    },
    {
        value: 'America/Whitehorse',
        timezone: 'Pacific Time - Whitehorse (GMT-08:00)',
    },
    { value: 'Pacific/Pitcairn', timezone: 'Pitcairn (GMT-08:00)' },
    { value: 'America/Denver', timezone: 'Mountain Time (GMT-07:00)' },
    {
        value: 'America/Phoenix',
        timezone: 'Mountain Time - Arizona (GMT-07:00)',
    },
    {
        value: 'America/Mazatlan',
        timezone: 'Mountain Time - Chihuahua, Mazatlan (GMT-07:00)',
    },
    {
        value: 'America/Dawson_Creek',
        timezone: 'Mountain Time - Dawson Creek (GMT-07:00)',
    },
    {
        value: 'America/Edmonton',
        timezone: 'Mountain Time - Edmonton (GMT-07:00)',
    },
    {
        value: 'America/Hermosillo',
        timezone: 'Mountain Time - Hermosillo (GMT-07:00)',
    },
    {
        value: 'America/Yellowknife',
        timezone: 'Mountain Time - Yellowknife (GMT-07:00)',
    },
    { value: 'America/Belize', timezone: 'Belize (GMT-06:00)' },
    { value: 'America/Chicago', timezone: 'Central Time (GMT-06:00)' },
    {
        value: 'America/Mexico_City',
        timezone: 'Central Time - Mexico City (GMT-06:00)',
    },
    { value: 'America/Regina', timezone: 'Central Time - Regina (GMT-06:00)' },
    {
        value: 'America/Tegucigalpa',
        timezone: 'Central Time - Tegucigalpa (GMT-06:00)',
    },
    {
        value: 'America/Winnipeg',
        timezone: 'Central Time - Winnipeg (GMT-06:00)',
    },
    { value: 'America/Costa_Rica', timezone: 'Costa Rica (GMT-06:00)' },
    { value: 'America/El_Salvador', timezone: 'El Salvador (GMT-06:00)' },
    { value: 'Pacific/Galapagos', timezone: 'Galapagos (GMT-06:00)' },
    { value: 'America/Guatemala', timezone: 'Guatemala (GMT-06:00)' },
    { value: 'America/Managua', timezone: 'Managua (GMT-06:00)' },
    { value: 'America/Cancun', timezone: 'America Cancun (GMT-05:00)' },
    { value: 'America/Bogota', timezone: 'Bogota (GMT-05:00)' },
    { value: 'Pacific/Easter', timezone: 'Easter Island (GMT-05:00)' },
    { value: 'America/New_York', timezone: 'Eastern Time (GMT-05:00)' },
    {
        value: 'America/Iqaluit',
        timezone: 'Eastern Time - Iqaluit (GMT-05:00)',
    },
    {
        value: 'America/Toronto',
        timezone: 'Eastern Time - Toronto (GMT-05:00)',
    },
    { value: 'America/Guayaquil', timezone: 'Guayaquil (GMT-05:00)' },
    { value: 'America/Havana', timezone: 'Havana (GMT-05:00)' },
    { value: 'America/Jamaica', timezone: 'Jamaica (GMT-05:00)' },
    { value: 'America/Lima', timezone: 'Lima (GMT-05:00)' },
    { value: 'America/Nassau', timezone: 'Nassau (GMT-05:00)' },
    { value: 'America/Panama', timezone: 'Panama (GMT-05:00)' },
    { value: 'America/Port-au-Prince', timezone: 'Port-au-Prince (GMT-05:00)' },
    { value: 'America/Rio_Branco', timezone: 'Rio Branco (GMT-05:00)' },
    {
        value: 'America/Halifax',
        timezone: 'Atlantic Time - Halifax (GMT-04:00)',
    },
    { value: 'America/Barbados', timezone: 'Barbados (GMT-04:00)' },
    { value: 'Atlantic/Bermuda', timezone: 'Bermuda (GMT-04:00)' },
    { value: 'America/Boa_Vista', timezone: 'Boa Vista (GMT-04:00)' },
    { value: 'America/Caracas', timezone: 'Caracas (GMT-04:00)' },
    { value: 'America/Curacao', timezone: 'Curacao (GMT-04:00)' },
    { value: 'America/Grand_Turk', timezone: 'Grand Turk (GMT-04:00)' },
    { value: 'America/Guyana', timezone: 'Guyana (GMT-04:00)' },
    { value: 'America/La_Paz', timezone: 'La Paz (GMT-04:00)' },
    { value: 'America/Manaus', timezone: 'Manaus (GMT-04:00)' },
    { value: 'America/Martinique', timezone: 'Martinique (GMT-04:00)' },
    { value: 'America/Port_of_Spain', timezone: 'Port of Spain (GMT-04:00)' },
    { value: 'America/Porto_Velho', timezone: 'Porto Velho (GMT-04:00)' },
    { value: 'America/Puerto_Rico', timezone: 'Puerto Rico (GMT-04:00)' },
    { value: 'America/Santo_Domingo', timezone: 'Santo Domingo (GMT-04:00)' },
    { value: 'America/Thule', timezone: 'Thule (GMT-04:00)' },
    {
        value: 'America/St_Johns',
        timezone: 'Newfoundland Time - St. Johns (GMT-03:30)',
    },
    { value: 'America/Araguaina', timezone: 'Araguaina (GMT-03:00)' },
    { value: 'America/Asuncion', timezone: 'Asuncion (GMT-03:00)' },
    { value: 'America/Belem', timezone: 'Belem (GMT-03:00)' },
    {
        value: 'America/Argentina/Buenos_Aires',
        timezone: 'Buenos Aires (GMT-03:00)',
    },
    { value: 'America/Campo_Grande', timezone: 'Campo Grande (GMT-03:00)' },
    { value: 'America/Cayenne', timezone: 'Cayenne (GMT-03:00)' },
    { value: 'America/Cuiaba', timezone: 'Cuiaba (GMT-03:00)' },
    { value: 'America/Fortaleza', timezone: 'Fortaleza (GMT-03:00)' },
    { value: 'America/Godthab', timezone: 'Godthab (GMT-03:00)' },
    { value: 'America/Maceio', timezone: 'Maceio (GMT-03:00)' },
    { value: 'America/Miquelon', timezone: 'Miquelon (GMT-03:00)' },
    { value: 'America/Montevideo', timezone: 'Montevideo (GMT-03:00)' },
    { value: 'Antarctica/Palmer', timezone: 'Palmer (GMT-03:00)' },
    { value: 'America/Paramaribo', timezone: 'Paramaribo (GMT-03:00)' },
    { value: 'America/Punta_Arenas', timezone: 'Punta Arenas (GMT-03:00)' },
    { value: 'America/Recife', timezone: 'Recife (GMT-03:00)' },
    { value: 'Antarctica/Rothera', timezone: 'Rothera (GMT-03:00)' },
    { value: 'America/Bahia', timezone: 'Salvador (GMT-03:00)' },
    { value: 'America/Santiago', timezone: 'Santiago (GMT-03:00)' },
    { value: 'Atlantic/Stanley', timezone: 'Stanley (GMT-03:00)' },
    { value: 'America/Noronha', timezone: 'Noronha (GMT-02:00)' },
    { value: 'America/Sao_Paulo', timezone: 'Sao Paulo (GMT-02:00)' },
    { value: 'Atlantic/South_Georgia', timezone: 'South Georgia (GMT-02:00)' },
    { value: 'Atlantic/Azores', timezone: 'Azores (GMT-01:00)' },
    { value: 'Atlantic/Cape_Verde', timezone: 'Cape Verde (GMT-01:00)' },
    { value: 'America/Scoresbysund', timezone: 'Scoresbysund (GMT-01:00)' },
    { value: 'Africa/Abidjan', timezone: 'Abidjan (GMT+00:00)' },
    { value: 'Africa/Accra', timezone: 'Accra (GMT+00:00)' },
    { value: 'Africa/Bissau', timezone: 'Bissau (GMT+00:00)' },
    { value: 'Atlantic/Canary', timezone: 'Canary Islands (GMT+00:00)' },
    { value: 'Africa/Casablanca', timezone: 'Casablanca (GMT+00:00)' },
    { value: 'America/Danmarkshavn', timezone: 'Danmarkshavn (GMT+00:00)' },
    { value: 'Europe/Dublin', timezone: 'Dublin (GMT+00:00)' },
    { value: 'Africa/El_Aaiun', timezone: 'El Aaiun (GMT+00:00)' },
    { value: 'Atlantic/Faroe', timezone: 'Faeroe (GMT+00:00)' },
    { value: 'Etc/GMT', timezone: 'GMT (no daylight saving) (GMT+00:00)' },
    { value: 'Europe/Lisbon', timezone: 'Lisbon (GMT+00:00)' },
    { value: 'Europe/London', timezone: 'London (GMT+00:00)' },
    { value: 'Africa/Monrovia', timezone: 'Monrovia (GMT+00:00)' },
    { value: 'Atlantic/Reykjavik', timezone: 'Reykjavik (GMT+00:00)' },
    { value: 'Africa/Algiers', timezone: 'Algiers (GMT+01:00)' },
    { value: 'Europe/Amsterdam', timezone: 'Amsterdam (GMT+01:00)' },
    { value: 'Europe/Andorra', timezone: 'Andorra (GMT+01:00)' },
    { value: 'Europe/Berlin', timezone: 'Berlin (GMT+01:00)' },
    { value: 'Europe/Brussels', timezone: 'Brussels (GMT+01:00)' },
    { value: 'Europe/Budapest', timezone: 'Budapest (GMT+01:00)' },
    {
        value: 'Europe/Belgrade',
        timezone: 'Central European Time - Belgrade (GMT+01:00)',
    },
    {
        value: 'Europe/Prague',
        timezone: 'Central European Time - Prague (GMT+01:00)',
    },
    { value: 'Africa/Ceuta', timezone: 'Ceuta (GMT+01:00)' },
    { value: 'Europe/Copenhagen', timezone: 'Copenhagen (GMT+01:00)' },
    { value: 'Europe/Gibraltar', timezone: 'Gibraltar (GMT+01:00)' },
    { value: 'Africa/Lagos', timezone: 'Lagos (GMT+01:00)' },
    { value: 'Europe/Luxembourg', timezone: 'Luxembourg (GMT+01:00)' },
    { value: 'Europe/Madrid', timezone: 'Madrid (GMT+01:00)' },
    { value: 'Europe/Malta', timezone: 'Malta (GMT+01:00)' },
    { value: 'Europe/Monaco', timezone: 'Monaco (GMT+01:00)' },
    { value: 'Africa/Ndjamena', timezone: 'Ndjamena (GMT+01:00)' },
    { value: 'Europe/Oslo', timezone: 'Oslo (GMT+01:00)' },
    { value: 'Europe/Paris', timezone: 'Paris (GMT+01:00)' },
    { value: 'Europe/Rome', timezone: 'Rome (GMT+01:00)' },
    { value: 'Europe/Stockholm', timezone: 'Stockholm (GMT+01:00)' },
    { value: 'Europe/Tirane', timezone: 'Tirane (GMT+01:00)' },
    { value: 'Africa/Tunis', timezone: 'Tunis (GMT+01:00)' },
    { value: 'Europe/Vienna', timezone: 'Vienna (GMT+01:00)' },
    { value: 'Europe/Warsaw', timezone: 'Warsaw (GMT+01:00)' },
    { value: 'Europe/Zurich', timezone: 'Zurich (GMT+01:00)' },
    { value: 'Asia/Amman', timezone: 'Amman (GMT+02:00)' },
    { value: 'Europe/Athens', timezone: 'Athens (GMT+02:00)' },
    { value: 'Asia/Beirut', timezone: 'Beirut (GMT+02:00)' },
    { value: 'Europe/Bucharest', timezone: 'Bucharest (GMT+02:00)' },
    { value: 'Africa/Cairo', timezone: 'Cairo (GMT+02:00)' },
    { value: 'Europe/Chisinau', timezone: 'Chisinau (GMT+02:00)' },
    { value: 'Asia/Damascus', timezone: 'Damascus (GMT+02:00)' },
    { value: 'Asia/Gaza', timezone: 'Gaza (GMT+02:00)' },
    { value: 'Europe/Helsinki', timezone: 'Helsinki (GMT+02:00)' },
    { value: 'Asia/Jerusalem', timezone: 'Jerusalem (GMT+02:00)' },
    { value: 'Africa/Johannesburg', timezone: 'Johannesburg (GMT+02:00)' },
    { value: 'Africa/Khartoum', timezone: 'Khartoum (GMT+02:00)' },
    { value: 'Europe/Kiev', timezone: 'Kiev (GMT+02:00)' },
    { value: 'Africa/Maputo', timezone: 'Maputo (GMT+02:00)' },
    {
        value: 'Europe/Kaliningrad',
        timezone: 'Moscow-01 - Kaliningrad (GMT+02:00)',
    },
    { value: 'Asia/Nicosia', timezone: 'Nicosia (GMT+02:00)' },
    { value: 'Europe/Riga', timezone: 'Riga (GMT+02:00)' },
    { value: 'Europe/Sofia', timezone: 'Sofia (GMT+02:00)' },
    { value: 'Europe/Tallinn', timezone: 'Tallinn (GMT+02:00)' },
    { value: 'Africa/Tripoli', timezone: 'Tripoli (GMT+02:00)' },
    { value: 'Europe/Vilnius', timezone: 'Vilnius (GMT+02:00)' },
    { value: 'Africa/Windhoek', timezone: 'Windhoek (GMT+02:00)' },
    { value: 'Asia/Baghdad', timezone: 'Baghdad (GMT+03:00)' },
    { value: 'Europe/Istanbul', timezone: 'Istanbul (GMT+03:00)' },
    { value: 'Europe/Minsk', timezone: 'Minsk (GMT+03:00)' },
    { value: 'Europe/Moscow', timezone: 'Moscow+00 - Moscow (GMT+03:00)' },
    { value: 'Africa/Nairobi', timezone: 'Nairobi (GMT+03:00)' },
    { value: 'Asia/Qatar', timezone: 'Qatar (GMT+03:00)' },
    { value: 'Asia/Riyadh', timezone: 'Riyadh (GMT+03:00)' },
    { value: 'Antarctica/Syowa', timezone: 'Syowa (GMT+03:00)' },
    { value: 'Asia/Tehran', timezone: 'Tehran (GMT+03:30)' },
    { value: 'Asia/Baku', timezone: 'Baku (GMT+04:00)' },
    { value: 'Asia/Dubai', timezone: 'Dubai (GMT+04:00)' },
    { value: 'Indian/Mahe', timezone: 'Mahe (GMT+04:00)' },
    { value: 'Indian/Mauritius', timezone: 'Mauritius (GMT+04:00)' },
    { value: 'Europe/Samara', timezone: 'Moscow+01 - Samara (GMT+04:00)' },
    { value: 'Indian/Reunion', timezone: 'Reunion (GMT+04:00)' },
    { value: 'Asia/Tbilisi', timezone: 'Tbilisi (GMT+04:00)' },
    { value: 'Asia/Yerevan', timezone: 'Yerevan (GMT+04:00)' },
    { value: 'Asia/Kabul', timezone: 'Kabul (GMT+04:30)' },
    { value: 'Asia/Aqtau', timezone: 'Aqtau (GMT+05:00)' },
    { value: 'Asia/Aqtobe', timezone: 'Aqtobe (GMT+05:00)' },
    { value: 'Asia/Ashgabat', timezone: 'Ashgabat (GMT+05:00)' },
    { value: 'Asia/Dushanbe', timezone: 'Dushanbe (GMT+05:00)' },
    { value: 'Asia/Karachi', timezone: 'Karachi (GMT+05:00)' },
    { value: 'Indian/Kerguelen', timezone: 'Kerguelen (GMT+05:00)' },
    { value: 'Indian/Maldives', timezone: 'Maldives (GMT+05:00)' },
    { value: 'Antarctica/Mawson', timezone: 'Mawson (GMT+05:00)' },
    {
        value: 'Asia/Yekaterinburg',
        timezone: 'Moscow+02 - Yekaterinburg (GMT+05:00)',
    },
    { value: 'Asia/Tashkent', timezone: 'Tashkent (GMT+05:00)' },
    { value: 'Asia/Colombo', timezone: 'Colombo (GMT+05:30)' },
    { value: 'Asia/Kolkata', timezone: 'India Standard Time (GMT+05:30)' },
    { value: 'Asia/Kathmandu', timezone: 'Kathmandu (GMT+05:45)' },
    { value: 'Asia/Almaty', timezone: 'Almaty (GMT+06:00)' },
    { value: 'Asia/Bishkek', timezone: 'Bishkek (GMT+06:00)' },
    { value: 'Indian/Chagos', timezone: 'Chagos (GMT+06:00)' },
    { value: 'Asia/Dhaka', timezone: 'Dhaka (GMT+06:00)' },
    { value: 'Asia/Omsk', timezone: 'Moscow+03 - Omsk (GMT+06:00)' },
    { value: 'Asia/Thimphu', timezone: 'Thimphu (GMT+06:00)' },
    { value: 'Antarctica/Vostok', timezone: 'Vostok (GMT+06:00)' },
    { value: 'Indian/Cocos', timezone: 'Cocos (GMT+06:30)' },
    { value: 'Asia/Yangon', timezone: 'Rangoon (GMT+06:30)' },
    { value: 'Asia/Bangkok', timezone: 'Bangkok (GMT+07:00)' },
    { value: 'Indian/Christmas', timezone: 'Christmas (GMT+07:00)' },
    { value: 'Antarctica/Davis', timezone: 'Davis (GMT+07:00)' },
    { value: 'Asia/Saigon', timezone: 'Hanoi (GMT+07:00)' },
    { value: 'Asia/Hovd', timezone: 'Hovd (GMT+07:00)' },
    { value: 'Asia/Jakarta', timezone: 'Jakarta (GMT+07:00)' },
    {
        value: 'Asia/Krasnoyarsk',
        timezone: 'Moscow+04 - Krasnoyarsk (GMT+07:00)',
    },
    { value: 'Asia/Brunei', timezone: 'Brunei (GMT+08:00)' },
    { value: 'Asia/Shanghai', timezone: 'China Time - Beijing (GMT+08:00)' },
    { value: 'Asia/Choibalsan', timezone: 'Choibalsan (GMT+08:00)' },
    { value: 'Asia/Hong_Kong', timezone: 'Hong Kong (GMT+08:00)' },
    { value: 'Asia/Kuala_Lumpur', timezone: 'Kuala Lumpur (GMT+08:00)' },
    { value: 'Asia/Macau', timezone: 'Macau (GMT+08:00)' },
    { value: 'Asia/Makassar', timezone: 'Makassar (GMT+08:00)' },
    { value: 'Asia/Manila', timezone: 'Manila (GMT+08:00)' },
    { value: 'Asia/Irkutsk', timezone: 'Moscow+05 - Irkutsk (GMT+08:00)' },
    { value: 'Asia/Singapore', timezone: 'Singapore (GMT+08:00)' },
    { value: 'Asia/Taipei', timezone: 'Taipei (GMT+08:00)' },
    { value: 'Asia/Ulaanbaatar', timezone: 'Ulaanbaatar (GMT+08:00)' },
    { value: 'Australia/Perth', timezone: 'Western Time - Perth (GMT+08:00)' },
    { value: 'Asia/Pyongyang', timezone: 'Pyongyang (GMT+08:30)' },
    { value: 'Asia/Dili', timezone: 'Dili (GMT+09:00)' },
    { value: 'Asia/Jayapura', timezone: 'Jayapura (GMT+09:00)' },
    { value: 'Asia/Yakutsk', timezone: 'Moscow+06 - Yakutsk (GMT+09:00)' },
    { value: 'Pacific/Palau', timezone: 'Palau (GMT+09:00)' },
    { value: 'Asia/Seoul', timezone: 'Seoul (GMT+09:00)' },
    { value: 'Asia/Tokyo', timezone: 'Tokyo (GMT+09:00)' },
    {
        value: 'Australia/Darwin',
        timezone: 'Central Time - Darwin (GMT+09:30)',
    },
    {
        value: 'Antarctica/DumontDUrville',
        timezone: "Dumont D'Urville (GMT+10:00)",
    },
    {
        value: 'Australia/Brisbane',
        timezone: 'Eastern Time - Brisbane (GMT+10:00)',
    },
    { value: 'Pacific/Guam', timezone: 'Guam (GMT+10:00)' },
    {
        value: 'Asia/Vladivostok',
        timezone: 'Moscow+07 - Vladivostok (GMT+10:00)',
    },
    { value: 'Pacific/Port_Moresby', timezone: 'Port Moresby (GMT+10:00)' },
    { value: 'Pacific/Chuuk', timezone: 'Truk (GMT+10:00)' },
    {
        value: 'Australia/Adelaide',
        timezone: 'Central Time - Adelaide (GMT+10:30)',
    },
    { value: 'Antarctica/Casey', timezone: 'Casey (GMT+11:00)' },
    {
        value: 'Australia/Hobart',
        timezone: 'Eastern Time - Hobart (GMT+11:00)',
    },
    {
        value: 'Australia/Sydney',
        timezone: 'Eastern Time - Melbourne, Sydney (GMT+11:00)',
    },
    { value: 'Pacific/Efate', timezone: 'Efate (GMT+11:00)' },
    { value: 'Pacific/Guadalcanal', timezone: 'Guadalcanal (GMT+11:00)' },
    { value: 'Pacific/Kosrae', timezone: 'Kosrae (GMT+11:00)' },
    { value: 'Asia/Magadan', timezone: 'Moscow+08 - Magadan (GMT+11:00)' },
    { value: 'Pacific/Norfolk', timezone: 'Norfolk (GMT+11:00)' },
    { value: 'Pacific/Noumea', timezone: 'Noumea (GMT+11:00)' },
    { value: 'Pacific/Pohnpei', timezone: 'Ponape (GMT+11:00)' },
    { value: 'Pacific/Funafuti', timezone: 'Funafuti (GMT+12:00)' },
    { value: 'Pacific/Kwajalein', timezone: 'Kwajalein (GMT+12:00)' },
    { value: 'Pacific/Majuro', timezone: 'Majuro (GMT+12:00)' },
    {
        value: 'Asia/Kamchatka',
        timezone: 'Moscow+09 - Petropavlovsk-Kamchatskiy (GMT+12:00)',
    },
    { value: 'Pacific/Nauru', timezone: 'Nauru (GMT+12:00)' },
    { value: 'Pacific/Tarawa', timezone: 'Tarawa (GMT+12:00)' },
    { value: 'Pacific/Wake', timezone: 'Wake (GMT+12:00)' },
    { value: 'Pacific/Wallis', timezone: 'Wallis (GMT+12:00)' },
    { value: 'Pacific/Auckland', timezone: 'Auckland (GMT+13:00)' },
    { value: 'Pacific/Enderbury', timezone: 'Enderbury (GMT+13:00)' },
    { value: 'Pacific/Fakaofo', timezone: 'Fakaofo (GMT+13:00)' },
    { value: 'Pacific/Fiji', timezone: 'Fiji (GMT+13:00)' },
    { value: 'Pacific/Tongatapu', timezone: 'Tongatapu (GMT+13:00)' },
    { value: 'Pacific/Apia', timezone: 'Apia (GMT+14:00)' },
    { value: 'Pacific/Kiritimati', timezone: 'Kiritimati (GMT+14:00)' },
];
