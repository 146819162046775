import { Link, navigate } from 'gatsby';
import { message, Form, Input, Button, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { timezones } from '../../data/timezones';
import AccountsList from '../accountsList';
import { randomString } from '../../config/functions';
import SheetInfo from '../sheetInfo';

const { Option } = Select;

let breakTheLoop = false;

const GoogleSheetFormSetup = ({ location, uid, uEmail }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [connectionId, setConnectionId] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');

        if (code !== null && uid !== '') {
            message.loading('Authenticating...', 10);
            axios
                .post(
                    `${process.env.GATSBY_BASE_URL}/auth/google`,
                    {
                        code,
                        uid,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then((response) => {
                    navigate(`/forms/google-sheet`);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                });
        }
    }, [uid]);

    const createForm = async (values) => {
        setLoading(true);
        if (connectionId !== '' && connectionId !== undefined) {
            values.sheetAccountId = connectionId;
            values.uid = uid;
            values.form_database_type = 'sheet';
            values.formId = randomString(8, 'aA#');

            const { data, error } = await axios.post(
                `${process.env.GATSBY_BASE_URL}/forms/create`,
                { formData: values, email: uEmail },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            setLoading(false);
            if (!error) navigate('/forms');
        } else {
            message.info(
                'There are a problem with your google sheet authentication.'
            );
        }
    };

    const setOauthAccount = (id) => {
        setConnectionId(id);
    };

    return (
        <div className="form-setup">
            <h2>With Google Sheet</h2>
            <p>Setup your Form backend with Google Sheet</p>
            <div className="form-section">
                <AccountsList
                    uid={uid}
                    selectedConnectionID={setOauthAccount}
                />
                <br />
                <Form
                    form={form}
                    layout="vertical"
                    name="sheetForm"
                    autoComplete="off"
                    initialValues={{}}
                    onFinish={createForm}
                >
                    <Form.Item
                        label="Give a name to form endpoint"
                        name="formName"
                        rules={[
                            { required: true, message: 'Please input a name!' },
                        ]}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="timezone"
                        label="Select Timezone"
                        rules={[
                            { required: true, message: 'Missing timezone' },
                        ]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {timezones.map((tim, key) => (
                                <Option value={tim.value}>
                                    {tim.timezone}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <SheetInfo accountId={connectionId} />

                    {/* <Form.Item
                        label="Spreadsheet ID"
                        name="sheetId"
                        rules={[
                            { required: true, message: 'Please input a name!' },
                        ]}
                        extra={
                            <div
                                style={{
                                    fontWeight: 300,
                                    fontSize: 14,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: "The Sheet id is the part after d/, in the below <b>example link</b>, it's 1mkabaGFBmlXENl5b-HLVRRCNU8Tx_y3Pe_gnyIREX1I-hDqQ, https://docs.google.com/spreadsheets/d/<b>1mkabaGFBmlXENl5b-HLVRRCNU8Tx_y3Pe_gnyIREX1I</b>/edit.",
                                }}
                            />
                        }
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        label="Tab ID"
                        name="tabId"
                        rules={[
                            { required: true, message: 'Please input a name!' },
                        ]}
                        extra={
                            <div
                                style={{
                                    fontWeight: 300,
                                    fontSize: 14,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: 'tab Id of your spreadsheet, where you want to push.',
                                }}
                            />
                        }
                    >
                        <Input autoComplete="off" />
                    </Form.Item> */}

                    <Form.Item>
                        <Button
                            loading={loading}
                            htmlType="submit"
                            type="primary"
                        >
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default GoogleSheetFormSetup;
