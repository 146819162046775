import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';

import SEO from '../blocks/layout/seo';
import FormContainer from '../blocks/layout/formContainer';
import FormsList from '../blocks/dashboard/formsList';
import FormDetails from '../blocks/dashboard/formDetails';
import GoogleSheetFormSetup from '../blocks/setup/sheet';
import AirtableFormSetup from '../blocks/setup/airtable';
import SupabaseFormSetup from '../blocks/setup/supabase';

import { Firebase } from '../config/firebase';
import { navigate } from 'gatsby';

const AllForms = ({ location }) => {
    const [uId, setUId] = useState('');
    const [uEmail, setUEmail] = useState('');

    function onChange(user) {
        if (user) {
            setUId(user.uid);
            setUEmail(user.email);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        const unsubscribe = Firebase.auth().onAuthStateChanged(onChange);

        // unsubscribe to the listener when unmounting
        return () => unsubscribe();
    }, []);

    return (
        <>
            <SEO title="Form with Sheet — One place for all your forms" />
            <FormContainer menuKey="1">
                <Router>
                    <FormsList path="/forms" uid={uId} location={location} />
                    <FormDetails
                        location={location}
                        uid={uId}
                        path="/forms/:formId"
                    />
                    <GoogleSheetFormSetup
                        uid={uId}
                        uEmail={uEmail}
                        location={location}
                        path="/forms/google-sheet"
                    />
                    <AirtableFormSetup
                        location={location}
                        uid={uId}
                        path="/forms/airtable"
                    />
                    <SupabaseFormSetup
                        location={location}
                        uid={uId}
                        path="/forms/supabase"
                    />
                </Router>
            </FormContainer>
        </>
    );
};

export default AllForms;
