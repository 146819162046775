import React, { useState } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import { navigate } from 'gatsby';
import axios from 'axios';

const AirtableAccountModal = ({ uid, type }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const addAccount = (values) => {
        setLoading(true);
        values.form_database_type = 'airtable';

        axios
            .post(
                `${process.env.GATSBY_BASE_URL}/addAccount`,
                {
                    uid,
                    airtableObj: values,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                setLoading(false);
                setIsModalVisible(false);
                navigate(`/forms/airtable`);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            });
    };

    return (
        <>
            {type === 'more' ? (
                <div onClick={showModal}>Or, add another account</div>
            ) : (
                <div>
                    <p style={{ color: '#606060' }}>
                        Get your Airtable API key & add the account first.
                    </p>
                    <Button type="primary" onClick={showModal}>
                        Add Airtable Account
                    </Button>
                </div>
            )}
            <Modal
                title="Basic Modal"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="airtableAccountForm"
                    autoComplete="off"
                    initialValues={{}}
                    onFinish={addAccount}
                >
                    <Form.Item
                        label="Give a name"
                        name="accountName"
                        rules={[
                            {
                                required: true,
                                message: 'Please input a name!',
                            },
                        ]}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        label="Airtable API key"
                        name="apiKey"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Airtable API key!',
                            },
                        ]}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={loading}
                            htmlType="submit"
                            type="primary"
                        >
                            Save Airtable Account
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AirtableAccountModal;
