import React, { useEffect, useState } from 'react';
import { Select, Input, Button, Divider } from 'antd';
import { RiKey2Line } from 'react-icons/ri';
import axios from 'axios';
import moment from 'moment';
import { GoogleOutlined } from '@ant-design/icons';

const { Option } = Select;

const AccountsList = ({ uid, selectedConnectionID }) => {
    const [sheetAccounts, setSheetAccounts] = useState([]);

    useEffect(async () => {
        const { data, error } = await axios.get(
            `${process.env.GATSBY_BASE_URL}/auth/accounts`,
            {
                params: {
                    uid,
                    form_database_type: 'sheet',
                },
            }
        );
        setSheetAccounts(data);
        if (data.length > 0) selectedConnectionID(data[0]._id);
    }, [uid]);

    const handleChange = (value) => {
        selectedConnectionID(value);
    };

    return (
        <div className="oauth-accounts">
            <>
                {sheetAccounts.length > 0 ? (
                    <>
                        <label>Choose account</label>
                        <Input.Group compact>
                            <Select
                                defaultValue={sheetAccounts[0]._id}
                                style={{ width: '100%' }}
                                onChange={handleChange}
                            >
                                {sheetAccounts.map((item) => (
                                    <Option key={item._id} value={item._id}>
                                        {item.name} - added{' '}
                                        {moment(item.updated_date).fromNow()}
                                    </Option>
                                ))}
                            </Select>
                        </Input.Group>
                        <a href={`${process.env.GATSBY_BASE_URL}/auth/google`}>
                            Or, add another account
                        </a>
                        <br />
                    </>
                ) : (
                    <div>
                        <Divider />
                        <p style={{ color: '#606060' }}>
                            Authenticate first with your Google Account
                        </p>
                        <a
                            href={`${process.env.GATSBY_BASE_URL}/auth/google`}
                            className="authenticate-btn"
                        >
                            <Button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src="/google-logo.png"
                                    alt="google login"
                                    height="25px"
                                    width="25px"
                                    style={{
                                        marginRight: 15,
                                    }}
                                />
                                <span>Sign in with Google</span>
                            </Button>
                        </a>
                    </div>
                )}
            </>
        </div>
    );
};

export default AccountsList;
